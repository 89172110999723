import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPersonCircleQuestion,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import CustomHook from "./CustomHook";

function Projects() {
  const [listProjects] = useState([
    {
      name: "Dev Flow",
      des: "Dev Flow is a comprehensive suite of tools meticulously designed specifically for developers. Say goodbye to scattering your code across multiple random websites. Dev Flow consolidates all your essential utilities into a single, convenient platform. Simply choose the tool you need from the left sidebar to complete your tasks swiftly and efficiently.",
      mission: "Full-stack Developer, system analysis and design",
      language: "ReactJs, NestJs, Mongoose Cloud,...",
      images: "/devflow-site.png",
    },
  ]);
  const divs = useRef([]);
  const scrollTab = useRef();
  CustomHook(scrollTab, divs);
  return (
    <section className="projects" ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        This is my Projects
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        I have completed several web development projects that highlighted my
        skills. These projects helped me utilize HTML, CSS, and JavaScript while
        also experimenting with frameworks like React, Vue and NestJs. I
        prioritized user-friendly designs and high performance. I'm eager to
        take on new projects that will test my skills and broaden my experience.
      </div>
      <div className="list">
        {listProjects.map((value, key) => (
          <div
            className="item"
            key={key}
            ref={(el) => el && divs.current.push(el)}
          >
            <div className="images">
              <img src={value.images} alt="" />
            </div>
            <div className="content">
              <h3>{value.name}</h3>
              <div className="des">{value.des}</div>
              <div className="des">
                <a href="https://devflow.io.vn/" target="_blank">
                  https://devflow.io.vn
                </a>
              </div>
              <div className="mission">
                <div>
                  <FontAwesomeIcon icon={faPersonCircleQuestion} />
                </div>
                <div>
                  <h4>Mission</h4>
                  <div className="des">{value.mission}</div>
                </div>
              </div>
              <div className="mission">
                <div>
                  <FontAwesomeIcon icon={faEarthAmericas} />
                </div>
                <div>
                  <h4>Languages</h4>
                  <div className="des">{value.language}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
export default Projects;
