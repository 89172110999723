import React, { useRef } from "react";
import CustomHook from "./CustomHook";
function Home() {
  const scrollTab = useRef();
  CustomHook(scrollTab);

  return (
    <section ref={scrollTab} className="home">
      <div className="content">
        <div className="name">
          MY NAME IS <span>NGUYEN TRAN</span>
        </div>
        <div className="des">
          My full name is Tran Huu Thuc Nguyen, and I have a profound interest
          in web development. I have developed a keen enthusiasm for designing
          engaging, responsive, and user-friendly web applications. With a
          robust foundation and skill set in front-end development, I focus on
          creating dynamic user interfaces that emphasize both functionality and
          aesthetics. My experience enables me to effectively utilize modern
          frameworks and libraries while consistently keeping abreast of the
          latest trends and technologies in the industry. I am committed to
          ongoing learning and providing high-quality, innovative web solutions.
        </div>

        <a
          href="/cv-nguyentran.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="animation active "
        >
          Download My CV
        </a>
      </div>
      <div className="avatar">
        <div className="card">
          <img src="/ng-avatar.jpg" alt="avatar" />
          <div className="info">
            <div>Developer</div>
            <div>VietNamese</div>
            <div>29/08/2000</div>
            <div>Male</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
